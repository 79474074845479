<template>
  <div>
    scrapDetail
  </div>
</template>

<script>
export default {
  name: "scrapDetail"
};
</script>

<style lang="less" scoped></style>
